import * as React from 'react';

import OrderSuccess from '~containers/checkout/order-success';
import Layout from '~components/layout';
import {firebaseAnalytics} from '~utils/firebase';
import Seo from '~components/seo';

const OrderSuccessPage = props => {
  React.useEffect(() => {
    firebaseAnalytics.logEvent('M_Order_Success_visited');
  }, []);

  return (
    <Layout>
      <Seo title='Thanh toán thành công' />
      <OrderSuccess {...props} />
    </Layout>
  );
};

export default OrderSuccessPage;
