import React from 'react';
import {Result, Button} from 'antd';
import {navigate} from 'gatsby';
import {connect} from 'react-redux';
import './styles.scss';

const OrderSuccess = () => {
  return (
    <div className='order-success'>
      {/* {isGuest ? ( // to do later
        <Result
          status='success'
          title='Đơn hàng được tạo thành công!!!'
          subTitle='Bạn có muốn đăng ký tài khoản từ thông tin đơn hàng để có trải nghiệm tốt hơn?'
          extra={[
            <Button key='return' onClick={() => navigate('/home')}>
              Tiếp tục mua hàng
            </Button>,
            <Button
              type='primary'
              key='register'
              onClick={() =>
                navigate('/register', {
                  state: {isGetInfoFromStorage: true},
                })
              }
            >
              Đăng ký
            </Button>,
          ]}
        />
      ) : ( */}
      <Result
        status='success'
        title='Đơn hàng được tạo thành công!!!'
        extra={[
          <Button type='primary' key='return' onClick={() => navigate('/home')}>
            Tiếp tục mua hàng
          </Button>,
        ]}
      />
      {/* )} */}
    </div>
  );
};

export default connect(state => ({
  isGuest: state.user.isGuest,
}))(OrderSuccess);
